import apiClient from '../../../api-client';
import { Bulksim } from '../../containers/telecom/sim/ActivateSimBulk';
import { TransferInput } from './TransferInput';

export class ActionsService {
  public async requestNewSimCard(amount: number, address: string, companyId: number) {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/request-new-sim-card`;

      const response = await apiClient.post(url, {
        amount,
        address
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async swapSimCard(companyId: number, newSimId: string | undefined, oldSimId: number, isEsim: boolean): Promise<void> {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/swap-sim-card`;

      const response = await apiClient.post(url, {
        newSimId,
        isEsim,
        oldSimId
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async transferPayAndGo(companyId: number, simId: number, expiryDate: Date): Promise<void> {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/transfer-pay-and-go`;

      const response = await apiClient.post(url, {
        simId,
        expiryDate,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async changeTarif(companyId: number, simId: number, newTariffId: number): Promise<void> {

    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/tarif-change`;

      const response = await apiClient.post(url, {
        simId,
        newTariffId,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async requestPuk(companyId: number, simId: number): Promise<void> {

    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/request-puk`;

      const response = await apiClient.post(url, {
        simId
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async cancelNumber(companyId: number, simId: number, endDate: Date): Promise<void> {

    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/cancel-number`;

      const response = await apiClient.post(url, {
        simId,
        endDate
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async changeSimOptions(companyId: number, optionId: number, active: boolean, simId: number, startDate?: Date, endDate?: Date): Promise<void> {

    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/change-sim-options`;

      const response = await apiClient.post(url, {
        optionId,
        active,
        simId,
        startDate,
        endDate
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async suspendSimCard(companyId: number, simId: number, startDate: Date, endDate?: Date): Promise<void> {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/suspend-sim-card`;

      const response = await apiClient.post(url, {
        simId,
        startDate,
        endDate
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async reactiveSimCard(companyId: number, simId: number): Promise<void> {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/reactivate-sim-card`;

      const response = await apiClient.post(url, {
        simId
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async changeSimServices(companyId: number, simId: number, service: string, active: boolean): Promise<void> {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/change-sim-services`;

      const response = await apiClient.post(url, {
        simId,
        service,
        active
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async transferNumber(companyId: number, input: TransferInput) {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/transfer-number`;

      const response = await apiClient.post(url, {
        ...input
      });
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error requesting simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }

  public async bulkSimActivation(companyId: number, sims: Bulksim[]) {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/actions/bulk-activation`;
      const response = await apiClient.post(url, {...sims});
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error uploading simcards ${error.response?.statusText || error.message
        }`
      );
    }
  }
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TicketService } from '../../services/tickets/Tickets';

export interface Ticket {
  id: number;
  subject: string;
  description: string;
  status: string;
  created_at: string;
  medewerker?: {
    med_naam: string;
    med_foto: string;
  };
  custom_fields?: { id: number; value: string }[];
}

export interface TicketsState {
  tickets: Ticket[];
  closedTickets: Ticket[];
  loading: boolean;
  error: string | null;
}

const initialState: TicketsState = {
  tickets: [],
  closedTickets: [],
  loading: false,
  error: null,
};

export const fetchTickets = createAsyncThunk(
  'tickets/fetchTickets',
  async (companyId: number) => {
    const service = new TicketService();
    const data = await service.getTickets(companyId);
    return data.tickets;
  }
);

export const fetchClosedTickets = createAsyncThunk(
  'tickets/fetchClosedTickets',
  async (companyId: number) => {
    const service = new TicketService();
    const data = await service.getTickets(
      companyId,
      undefined,
      undefined,
      'Gesloten'
    );
    return data.tickets;
  }
);

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    updateTicket(state, action) {
      const updatedTicket = action.payload;
      const index = state.tickets.findIndex(
        (ticket) => ticket.id === updatedTicket.id
      );
      if (index !== -1) {
        state.tickets[index] = { ...state.tickets[index], ...updatedTicket };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTickets.pending, (state) => {
        state.loading = true;
        state.tickets = [];
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        state.loading = false;
        state.tickets = action.payload;
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch tickets';
      })
      .addCase(fetchClosedTickets.pending, (state) => {
        state.loading = true;
        state.closedTickets = [];
      })
      .addCase(fetchClosedTickets.fulfilled, (state, action) => {
        state.loading = false;
        state.closedTickets = action.payload;
      })
      .addCase(fetchClosedTickets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch closed tickets';
      });
  },
});

export const { updateTicket } = ticketsSlice.actions;
export default ticketsSlice.reducer;

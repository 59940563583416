import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BranchSelect from '../../../components/branches/BranchSelect';
import FormButton from '../../../components/buttons/FormButton';
import BranchForm from '../../../components/forms/branches/BranchForm';
import FormField from '../../../components/inputs/text/FormField';
import PopoverForm from '../../../components/popover/PopoverForm';
import Typography from '../../../components/typography/Typography';
import { clearAsides } from '../../../state/component/AsideSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { ActionsService } from '../../../services/tickets/Actions';

const RequestNewSimcards = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const actionService = new ActionsService();
  const dispatch = useDispatch();

  const handleSubmit = async (values: { amount: string, address: string }) => {
    setLoading(true);

    await actionService.requestNewSimCard(parseInt(values.amount), values.address, companyId!);

    dispatch(
      addToast({
        description: [
          'Aanvraag nieuwe simkaarten',
          { address: values.address },
        ],
        style: 'success',
        position: 'bottomRight',
      })
    );

    dispatch(clearAsides());
    setLoading(false);
  };

  return (
    <Form
      form={form}
      labelAlign="left"
      layout="vertical"
      onFinish={handleSubmit}
    >
      <div className="block mb-4">
        <Typography tag="span" type="default">
          Simkaarten worden opgestuurd
        </Typography>
      </div>

      <div className="mt-2">
        <FormField
          form={form}
          type="number"
          label="Aantal simkaarten"
          name="amount"
          placeholder="20"
          isRequired={true}
          rules={[{ validator: (rule, value) => value >= 5 && value <= 100 ? Promise.resolve() : Promise.reject('Aantal simkaarten moet groter zijn dan 5 en kleiner dan 100') }]}
        />
      </div>

      <Form.Item
        label={t('Leveradres')}
        name="address"
        rules={[{ required: true, message: 'Selecteer een leveradres' }]}
      >
        <BranchSelect
          loading={loading}
          handleSelect={(event, option) =>
            form.setFieldsValue({ address: option.label })
          }
          defaultValue={t('Selecteer adres')}
        />
      </Form.Item>
      <div>
        <PopoverForm
          form={
            <BranchForm
              branch={{}}
              source="AddressOverviewContainer"
            ></BranchForm>
          }
          button={<Button>Ander adres</Button>}
          title="Ander adres"
        />
      </div>

      <div className="col-span-3 mt-4">
        <FormButton form={true} text="Aanvraag indienen" />
      </div>
    </Form>
  );
};

export default RequestNewSimcards;

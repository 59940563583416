import { Form, Table, TableProps } from 'antd';
import React, { FC } from 'react';
import FormButton from '../../../components/buttons/FormButton';
import { Bulksim } from './ActivateSimBulk';

interface ActivateSimBulkForm {
  handleSubmit: any;
  loading: boolean;
  dataSource: Bulksim[];
  columns: any;
}
type ColumnTypes = Exclude<TableProps<Bulksim>['columns'], undefined>;

const ActivateSimBulkForm: FC<ActivateSimBulkForm> = ({
  handleSubmit,
  loading,
  dataSource,
  columns,
}) => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className={loading ? 'animate-pulse' : ''}
    >
      <Table<Bulksim>
        size="small"
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
      />
      {dataSource.length >= 1 && !loading ? (
        <FormButton form={true} text="Indienen" />
      ) : (
        ''
      )}
    </Form>
  );
};

export default ActivateSimBulkForm;

import { Progress } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../components/buttons/LoadingSpinner';
import SearchTable from '../../components/inputs/search/SearchTable';
import Table from '../../components/tables/Table';
import TableItem from '../../components/tables/TableItem';
import { LicenseService } from '../../services/licenses/Licenses';
import { UsersService } from '../../services/users/Users';
import { updateCompanyLicense } from '../../state/license/LicenseSlice';
import { RootState } from '../../state/store';

type License = {
  license: any;
  data: {
    license: any;
  };
};

const CurrentLicenses: FC<License> = ({ data: { license: licenseState } }) => {
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [license, setLicense] = useState<any>(licenseState);

  const licenseService = new LicenseService();
  const userService = new UsersService();

  const dispatch = useDispatch();
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const usersResponse = await userService.getUserNames(companyId, search);
        const licensedUserIds = new Set(
          license?.users?.map((user) => user?.id_user)
        );

        const filtered = usersResponse.filter((user) => {
          return !licensedUserIds.has(user.value);
        });

        setAllUsers(filtered);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filterUsers = (input: any[], searchValue: string = '') => {
    let filtered = input;

    

    if (searchValue.trim() !== '') {
      filtered = filtered.filter((user) => {
        const firstName = user?.geb_voornaam || user.label;
        const lastName = user?.geb_naam || user.label;
        return (
          String(firstName).toLowerCase().includes(searchValue.toLowerCase()) ||
          String(lastName).toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }

    return filtered;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleIncreaseItemCount = async (licenseId: number) => {
    setLoading(true);
    const userLicense = license.users.find(
      (user: any) => user.id === licenseId
    );

    if (license.aantal < userLicense.licentie_amount + 1) {
      return;
    }

    await licenseService.updateLicense(companyId, licenseId, {
      licentie_amount: userLicense.licentie_amount + 1,
    });

    const updatedLicense = {
      ...license,
      users: license.users.map((user: any) => {
        if (user.id === licenseId) {
          return { ...user, licentie_amount: user.licentie_amount + 1 };
        }
        return user;
      }),
    };

    dispatch(updateCompanyLicense(updatedLicense));
    setLicense(updatedLicense);
    setLoading(false);
  };

  const handleDecreaseItemCount = async (licenseId: number, userId: string) => {
    setLoading(true);
    const userLicense = license.users.find(
      (user: any) => user.id === licenseId
    );
    let updatedLicense = userLicense;

    

    if (userLicense.licentie_amount - 1 <= 0) {
      await licenseService.removeUser(licenseId, companyId);

      updatedLicense = {
        ...license,
        users: license.users.filter((user: any) => user.id !== licenseId),
      };
    } else {
      await licenseService.updateLicense(companyId, licenseId, {
        licentie_amount: userLicense.licentie_amount - 1,
      });

      updatedLicense = {
        ...license,
        users: license.users.map((user: any) => {
          if (user.id === licenseId) {
            return { ...user, licentie_amount: user.licentie_amount - 1 };
          }
          return user;
        }),
      };
    }

    dispatch(updateCompanyLicense(updatedLicense));
    setLicense(updatedLicense);
    setLoading(false);
  };

  const addUser = async (userId: string, name: string) => {
    setLoading(true);
    const licenseId = await licenseService.addUser(
      license.id,
      userId,
      companyId
    );

    const updatedLicense = {
      ...license,
      users: [
        ...license.users,
        {
          id: licenseId,
          id_user: userId,
          geb_naam: '',
          geb_voornaam: name,
          licentie_amount: 1,
        },
      ],
    };

    dispatch(updateCompanyLicense(updatedLicense));
    setLicense(updatedLicense);
    setLoading(false);
  };

  const tableHeaders: object = ['Naam', 'Aantal'];

  const fixedColumns = [
    { key: 'Productnummer', position: 'left' },
    { key: '', position: 'right' },
  ];

  const filteredUsers = filterUsers(license?.users || [], search);

  return (
    <div>
      <div className="mt-1">
        <Progress
          percent={(
            (license.users.reduce((acc, val) => acc + val.licentie_amount, 0) /
              license.aantal) *
            100
          ).toFixed(2)}
        />
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="overflow-y-hidden border dark:border-gray-700 rounded-lg mt-3">
          <SearchTable
            handleChange={handleChange}
            placeholder="Gebruiker zoeken..."
            value={search}
          />
          <Table header={tableHeaders} fixedColumns={fixedColumns}>
            {filteredUsers.map((userLicense: any) => (
              <tr key={userLicense?.id}>
                <TableItem type="tiny">
                  {userLicense?.geb_voornaam ?? ''}{' '}
                  {userLicense?.geb_naam ?? ''}
                </TableItem>
                <TableItem type="tiny">
                  <div className="flex gap-1 items-center text-base">
                    {userLicense.licentie_amount > 0 && (
                      <button
                        className="hover:text-red-500 px-2 py-1"
                        onClick={() =>
                          handleDecreaseItemCount(
                            userLicense?.id,
                            userLicense?.id_user
                          )
                        }
                      >
                        -
                      </button>
                    )}
                    {userLicense.licentie_amount || 0}
                    {userLicense.licentie_amount < license.aantal && (
                      <button
                        className="hover:text-green-500 px-2 py-1"
                        onClick={() => handleIncreaseItemCount(userLicense?.id)}
                      >
                        +
                      </button>
                    )}
                  </div>
                </TableItem>
              </tr>
            ))}
            {filterUsers(
              allUsers.filter(
                (v) => !license.users.find((u) => v.value === u.id_user)
              ),
              search
            ).map((user: any) => (
              <tr key={user.value}>
                <TableItem type="tiny">{user.label}</TableItem>
                <TableItem type="tiny">
                  <div className="flex gap-1 items-center text-base">
                    0
                    {license.users.reduce(
                      (acc, val) => acc + val.licentie_amount,
                      0
                    ) < license.aantal && (
                      <button
                        className="hover:text-green-500 px-2 py-1"
                        onClick={() => addUser(user.value, user.label)}
                      >
                        +
                      </button>
                    )}
                  </div>
                </TableItem>
              </tr>
            ))}
          </Table>
        </div>
      )}
    </div>
  );
};

export default CurrentLicenses;

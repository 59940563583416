import { Button, Form, Radio, Space, UploadFile } from 'antd';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import SelectTarif from '../../../organisms/simcards/SelectTarif';
import SimcardSelect from '../../../organisms/simcards/SimcardSelect';
import { SimcardService } from '../../../services/simcards/Simcards';
import { stackAside } from '../../../state/component/AsideSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import {
  CompanyModules,
  hasModule,
} from '../../../utils/auth/AuthorizationUtility';
import { SimMapper } from '../../../utils/mappers/SimMapper';
import BranchSelect from '../../branches/BranchSelect';
import FormButton from '../../buttons/FormButton';
import LoadingSpinner from '../../buttons/LoadingSpinner';
import TextHeader from '../../headers/TextHeader';
import DateInput from '../../inputs/dates/DateInput';
import FileUpload from '../../inputs/fileupload/FileUpload';
import SelectBox from '../../inputs/selectbox/SelectBox';
import InputField from '../../inputs/text/InputField';
import ToggleSwitch from '../../inputs/toggle/ToggleSwitch';
import Typography from '../../typography/Typography';
import UserSelect from '../../users/UserSelect';

interface props {
  onSubmit: (input: SimRequestInput) => unknown;
  loading: boolean;
  userId?: number;
}

interface FormValuesInterface {
  transferType?: string;
  operator?: string;
  subscriptionType?: string;
  isBudgetManager?: boolean;
  isESim?: boolean;
  date: Date;
}

interface FormSubmitValuesInterface {
  sim: string;
  tarif: any;
  date: any;
  isESim: any;
  isBudgetManager: any;
  user_id: string;
  transferType: string;
  gsmNumber: string;
  operator: string;
  subscriptionType: string;
  branch: any;
}

interface FileTypeInterface {
  base64String: string;
  name: string;
}

export interface SimRequestInput {
  branch: number;
  sim?: string;
  tarifId: number;
  date: string;
  isEsim: boolean;
  isBudgetmanager: boolean;
  userId: number;
  transferType: string;
  gsmNumber: string;
  operator: string;
  subscriptionType: string;
  loaFile?: string;
  idFile?: string;
  proximusTransferFile?: string;
  intekeningsFile?: string;
}

const simcardService = new SimcardService();

const SimOnboardingForm: FC<props> = ({ onSubmit, loading, userId }) => {
  const [form] = Form.useForm();
  const [loaFile, setLoaFile] = useState<UploadFile[]>([]);
  const [idFile, setIdFile] = useState<UploadFile[]>([]);
  const [overdrachtFile, setOverdrachtFile] = useState<UploadFile[]>([]);
  const [budgetmanagerFile, setBudgetManagerFile] = useState<UploadFile[]>([]);
  const [formValues, setFormValues] = useState<FormValuesInterface>({});
  const dispatch = useDispatch();
  const [createdUser, setCreatedUser] = useState(undefined);
  const [sims, setSims] = useState<SimcardInterface[]>([]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  )!;

  const { t } = useTranslation();

  const project = useSelector<any, any>(
    (state: RootState) => state.project.currentProject
  );
  const modules = useSelector((state: RootState) => state.auth.modules);

  const fetchSims = async () => {
    const sims = await simcardService.getInactiveSimcards(companyId);
    setSims(sims.sims as unknown as SimcardInterface[]);
  };

  useEffect(() => {
    fetchSims();
    if (formValues.transferType === 'Nieuwe nummer') {
      form.setFieldsValue({
        operator: undefined,
        subscriptionType: undefined,
      });
      setFormValues((prevValues) => ({
        ...prevValues,
        operator: undefined,
        subscriptionType: undefined,
      }));
    }
  }, [formValues.transferType, form]);

  const handleValuesChange = (_: any, allValues: any) => {
    setFormValues(allValues);
  };

  const createUser = () => {
    dispatch(
      stackAside({
        data: {},
        component: 'userCreate',
        title: 'Create User',
        callback: (value) => {
          setCreatedUser(value.userId);
          form.setFieldValue('user_id', value.userId);
        },
      })
    );
  };

  const handleBranchSelect = (value: any) => {
    form.setFieldValue('branch', value);
  };

  const providerOptions = [
    { value: 'Proximus', label: 'Proximus' },
    { value: 'Orange', label: 'Orange' },
    { value: 'Base', label: 'Base' },
    { value: 'Telenet', label: 'Telenet' },
    { value: 'Mobile Vikings', label: 'Mobile Vikings' },
    { value: 'Andere', label: 'Andere' },
  ];

  const fileToBase64 = (
    file: File
  ): Promise<{ base64String: string; name: string }> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          base64String: (reader.result as string)?.split(',')[1],
          name: file.name,
        });

      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (values: FormSubmitValuesInterface) => {
    const gebruikerId = project
      ? project.metadata[0].value
      : values
        ? values.user_id
        : '';

    if (
      !values.isESim &&
      (values.sim.length !== 13 || !values.sim.startsWith('2100'))
    ) {
      dispatch(
        addToast({
          description: t(
            'Een simkaart moet beginnen met 2100 en 13 cijfers bevatten of de optie eSIM moet gekozen zijn.'
          ),
          position: 'bottomRight',
          style: 'warning',
        })
      );

      return;
    }

    const data: SimRequestInput = {
      branch: values.branch,
      sim: values.isESim ? undefined : values.sim,
      tarifId: values.tarif.value,
      date: values.date.toDate(),
      isEsim: values.isESim,
      isBudgetmanager: values.isBudgetManager,
      userId: parseInt(gebruikerId),
      transferType: values.transferType,
      gsmNumber: values.gsmNumber,
      operator: values.operator,
      subscriptionType: values.subscriptionType,
    };

    if (loaFile[0]) {
      data.loaFile = (
        await fileToBase64(loaFile[0] as unknown as File)
      ).base64String;
    }

    if (idFile[0]) {
      data.idFile = (
        await fileToBase64(idFile[0] as unknown as File)
      ).base64String;
    }

    if (overdrachtFile[0]) {
      data.proximusTransferFile = (
        await fileToBase64(overdrachtFile[0] as unknown as File)
      ).base64String;
    }

    if (budgetmanagerFile[0]) {
      data.intekeningsFile = (
        await fileToBase64(budgetmanagerFile[0] as unknown as File)
      ).base64String;
    }

    onSubmit(data);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        esim: false,
        budgetmanager: false,
        date: dayjs(new Date()),
        transferType: 'Nieuwe nummer',
        subscriptionType: 'Herlaadkaart',
      }}
      onValuesChange={handleValuesChange}
      layout="vertical"
      className={loading ? 'animate-pulse' : ''}
    >
      <TextHeader
        title="Simkaarten"
        subtitle={'Nieuwe nummer of nummer overdragen'}
      />
      <Form.Item
        label="Gebruiker"
        name="user_id"
        rules={[
          {
            required: true,
            message: 'Selecteer een Gebruiker.',
          },
        ]}
      >
        <Space.Compact style={{ width: '100%' }}>
          <UserSelect
            key={createdUser}
            handleSelect={(value) => {
              form.setFieldsValue({ user_id: value });
            }}
            defaultValue={createdUser || userId}
          />
          <Button className="h-auto" onClick={() => createUser()}>
            {t('+ Gebruiker')}
          </Button>
        </Space.Compact>
      </Form.Item>
      <Form.Item
        label="Type overdracht"
        name="transferType"
        // initialValue="Nieuwe nummer"
        className="mt-5"
      >
        <Radio.Group
          onChange={(event) => {
            form.setFieldsValue({ transferType: event.target.value });
          }}
        >
          <Radio value={'Nieuwe nummer'}>{t('Nieuwe nummer')}</Radio>
          <Radio value={'Overdracht nummer'}>{t('Overdracht nummer')}</Radio>
        </Radio.Group>
      </Form.Item>
      {formValues.transferType === 'Overdracht nummer' ? (
        <>
          <div className="mb-1">
            <Typography tag={'h1'} type={'semibold'}>
              Voor we beginnen
            </Typography>
            <Typography tag={'p'} type={'label'}>
              Voor de activatie zijn enkele documenten nodig:
            </Typography>
            <ul className="mt-2">
              <li className="list-disc ml-5">
                <Typography tag={'p'} type={'default'}>
                  Download alvast de Letter Of Auhtorization (LOA) als het
                  nummer wordt overgedragen van een andere operator en neem een
                  kopie van de identiteitskaart van de huidige titularis.
                </Typography>
                <div className="mt-2 mb-5 flex gap-2">
                  <Button
                    href="../../../LOA_mobile_NL.pdf"
                    download="LOA_mobile_NL.pdf"
                    type="primary"
                  >
                    {t('Download LOA')}
                  </Button>
                  <Form.Item
                    name="loaFile"
                    rules={[
                      {
                        required:
                          formValues.transferType === 'Overdracht nummer'
                            ? true
                            : false,
                        message: t('Een LOA file is verplicht'),
                      },
                    ]}
                  >
                    <FileUpload
                      name="loaFile"
                      onChange={setLoaFile}
                      style="min"
                      fileTypes={['application/pdf']}
                      fileList={loaFile}
                      description="Upload LOA"
                    />
                  </Form.Item>
                  <FileUpload
                    onChange={setIdFile}
                    style="min"
                    fileTypes={['application/pdf']}
                    fileList={idFile}
                    description="(Optioneel) ID huidige titularis"
                  />
                </div>
              </li>
              {formValues.operator === 'Proximus' &&
              formValues.subscriptionType === 'Abonnement' ? (
                <li className="list-disc ml-5">
                  <Typography tag={'p'} type={'default'}>
                    Download het overdrachtsformulier van Proximus voor een
                    overdracht binnen Proximus.
                  </Typography>
                  <div className="mt-2 mb-5 flex gap-2">
                    <Button
                      href="../../../Overdrachtsdocument_Proximus_NL.pdf"
                      download="Overdrachtsdocument_Proximus_NL.pdf"
                      type="primary"
                    >
                      {t('Download overdrachtsformulier Proximus')}
                    </Button>
                    <Form.Item
                      name="transferFile"
                      rules={[
                        {
                          required:
                            formValues.operator === 'Proximus' &&
                            formValues.subscriptionType === 'Abonnement',
                          message: t('Een Overdracht file is verplicht'),
                        },
                      ]}
                    >
                      <FileUpload
                        name="transferFile"
                        onChange={setOverdrachtFile}
                        style="min"
                        fileTypes={['application/pdf']}
                        fileList={overdrachtFile}
                        description="Upload overdrachtsformulier Proximus"
                      />
                    </Form.Item>
                  </div>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
          <Form.Item
            label="Huidige GSM-nummer"
            name="gsmNumber"
            rules={[
              {
                required:
                  formValues.transferType === 'Overdracht nummer'
                    ? true
                    : false,
                message: t('Voer een geldig GSM-nummer in.'),
              },
            ]}
          >
            <InputField type="text" name="gsmNumber" placeholder="GSM-nummer" />
          </Form.Item>
          <Form.Item
            label="Huidige operator"
            name="operator"
            rules={[
              {
                required: formValues.transferType === 'Overdracht nummer',
                message: t('Selecteer een operator.'),
              },
            ]}
          >
            <SelectBox
              search={true}
              options={providerOptions}
              onChange={(event, option) =>
                form.setFieldsValue({ operator: option.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Maak je gebruik van een herlaadkaart of een abonnement?"
            name="subscriptionType"
            // initialValue="Herlaadkaart"
            rules={[
              {
                required: formValues.transferType === 'Overdracht nummer',
                message: t('Maak een keuze.'),
              },
            ]}
          >
            <Radio.Group
              onChange={(event) =>
                form.setFieldsValue({ subscriptionType: event.target.value })
              }
            >
              <Radio value={'Herlaadkaart'}>{t('Herlaadkaart')}</Radio>
              <Radio value={'Abonnement'}>{t('Abonnement')}</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      ) : (
        ''
      )}

      <div className="mb-2 grid grid-cols-5 gap-3">
        <div className="col-span-1">
          <Form.Item name="isESim" valuePropName="checked">
            <ToggleSwitch
              label="eSIM"
              onChange={(event) =>
                form.setFieldsValue({ isESim: !!event.target.checked })
              }
              name="isESim"
            />
          </Form.Item>
        </div>
        {hasModule(modules, CompanyModules.BUDGETMANAGER) && (
          <div className="col-span-4">
            <Form.Item name="isBudgetManager" valuePropName="checked">
              <ToggleSwitch
                label="Budgetmanager"
                checked={form.getFieldValue('isBudgetManager')}
                onChange={(event) =>
                  form.setFieldsValue({ isBudgetManager: event.target.checked })
                }
                name="isBudgetManager"
              />
            </Form.Item>
          </div>
        )}
      </div>
      {formValues.isBudgetManager && (
        <div>
          <Typography tag={'p'} type={'default'}>
            Voor budgetmanagers is er ook een bijkomend document nodig:
          </Typography>
          <div className="mt-2 mb-5 flex gap-2">
            <Button
              href="../../../BudgetManager_NL.pdf"
              download="IntekeningsdocumentBudgetmanager.pdf"
              type="primary"
            >
              {t('Download intekeningsdocument budgetmanager')}
            </Button>
            <Form.Item
              name="budgetManager"
              rules={[
                {
                  required: formValues.isBudgetManager,
                  message: 'Upload het bestand zoals aangegeven',
                },
              ]}
            >
              <FileUpload
                name="budgetManager"
                onChange={setBudgetManagerFile}
                style="min"
                fileTypes={['application/pdf']}
                fileList={budgetmanagerFile}
                description="Upload het intekeningsdocument hier"
              />
            </Form.Item>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 gap-x-2">
        <div className="col-span-1">
          <Form.Item
            label="Simkaart selecteren of ingeven"
            name="sim"
            rules={[
              {
                required: formValues.isESim ? false : true,
                message: 'Selecteer een simkaart.',
              },
            ]}
          >
            <SimcardSelect
              onSelect={(value) => form.setFieldsValue({ sim: value })}
              status={'inactive'}
              options={sims.map(SimMapper.mapSimToOptions)}
              error={undefined}
              disabled={formValues.isESim}
            />
          </Form.Item>
        </div>
        <div className="col-span-1">
          <Form.Item
            label="Tariefplan selecteren"
            name="tarif"
            rules={[
              {
                required: true,
                message: 'Selecteer een tariefplan.',
              },
            ]}
          >
            <SelectTarif
              onSelect={(_, option) => {
                form.setFieldsValue({
                  tarif: option,
                });
              }}
              defaultValue={undefined}
              type={'voice'}
            />
          </Form.Item>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <Form.Item
          label="Vestiging"
          name={'branch'}
          rules={[
            {
              required: true,
              message: 'Selecteer een vestiging.',
            },
          ]}
        >
          <BranchSelect
            handleSelect={handleBranchSelect}
            defaultValue={'Selecteer vestiging'}
          />
        </Form.Item>
        <Form.Item
          label="Uitvoeringsdatum"
          name="date"
          rules={[
            {
              required: true,
              message: 'Selecteer een uitvoeringsdatum.',
            },
          ]}
        >
          <DateInput
            onChange={(date) => form.setFieldsValue({ date: date })}
            value={formValues.date}
            endDate={project ? project.proj_deadline : ''}
          />
        </Form.Item>
      </div>
      <div className="mt-4">
        <FormButton form={true} text="Indienen" />
      </div>
      {loading ? <LoadingSpinner /> : ''}
    </Form>
  );
};

export default SimOnboardingForm;

import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import SelectTarif from '../../../organisms/simcards/SelectTarif';
import Cta from '../../buttons/Cta';
import DateRange from '../../inputs/dates/DateRange';
import Typography from '../../typography/Typography';
import RatePlanInfo from '../../typography/sim/RatePlanInfo';
import Form from '../Form';

import { Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import TarifRoaming from '../../../organisms/simcards/TarifRoaming';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TarifService } from '../../../services/simcards/Tarifs';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import LoadingSpinner from '../../buttons/LoadingSpinner';
import { SimStatusTag } from '../../tags/StatusTag';
import { ActionsService } from '../../../services/tickets/Actions';
import SelectOption from '../../../organisms/simcards/SelectOption';
import { DateUtil } from '../../../utils/dates/DateUtil';

type props = {
  currentValue: string | undefined;
  simData: SimcardInterface;
  setSim: any;
};

const AddOption: FC<props> = ({ currentValue, simData, setSim }) => {
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [date, setDate] = useState([dayjs()]);
  const [tarif, setTarif] = useState();
  const [roaming, setRoaming] = useState(undefined);

  const [options, setOptions] = useState(simData.sim_options || []);

  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const tarifService = new TarifService();
  const simCardService = new SimcardService();
  const actionService = new ActionsService();
  const dispatch = useDispatch();

  const handleChange = async (event: number) => {
    setRoaming(undefined);
    if (companyId) {
      setDataLoading(true);
      setTarif(event);

      const tarifDetail = await tarifService.getTarif(event, companyId);
      setRoaming(tarifDetail);
      setDataLoading(false);
    }
  };

  const handleDate = (date) => {
    setDate(date);
  };

  const removeOption = async () => {
    setLoading(true);

    const option = options[0];
    await actionService.changeSimOptions(companyId!, option.apt_id, false, simData.sim_id);

    setSim((state: SimcardInterface) => ({
      ...state,
      sim_options: [],
    }));

    setOptions([]);
    setRoaming(undefined);

    dispatch(
      patchSimcard({ props: { sim_options: [] }, simId: simData.sim_id })
    );

    setLoading(false);

    dispatch(
      addToast({
        description: [
          'Opzeggen optie',
          {
            trf_naam: simData.sim_options[0]?.trf_naam,
            sim_unique_nr: simData.sim_unique_nr,
          },
        ],
        position: 'bottomRight',
        style: 'success',
      })
    );

    dispatch(
      addToast({
        description: 'Er is een ticket aangemaakt.',
        position: 'bottomRight',
        style: 'success',
      })
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);

    if (!date.length) {
      setLoading(false);
      return false;
    }

    if (!tarif) {
      setLoading(false);
      return false;
    }

    await actionService.changeSimOptions(companyId!, tarif, true, simData.sim_id, 
      DateUtil.localDateToUTC(date[0].toDate()), 
      date[1] ? DateUtil.localDateToUTC(date[1]?.toDate()) : undefined
    );

    const sim = await simCardService.getSimcard(simData.sim_id, companyId!);
    const prop = {
      sim_isActive: SimStatusTag.ADD_OPTION,
      sim_options: sim.sim_options,
    };

    setOptions(sim.sim_options);

    setSim((state: SimcardInterface) => ({
      ...state,
      ...prop,
    }));
    dispatch(patchSimcard({ props: prop, simId: simData.sim_id }));


    setLoading(false);
  };

  return (
    <div>
      {!options || options.length === 0 ? (
        <Form
          buttonLabel="Indienen"
          handleSubmit={handleSubmit}
          gridSize="grid-cols-2"
          loading={loading}
        >
          <Typography tag="label" type="label">
            Selecteer een optie
          </Typography>
          <SelectOption
            onSelect={handleChange}
            defaultValue={currentValue}
            type="option"
          />
          {dataLoading && (
            <div className="mt-2">
              <LoadingSpinner />
            </div>
          )}
          {tarif && <TarifRoaming roaming={roaming} />}
          <div className="block mt-2 mb-2">
            <Typography tag="label" type="label">
              Start en einddatum
            </Typography>
            <div className="block">
              <DateRange onChange={handleDate} value={date} />
            </div>
          </div>
        </Form>
      ) : (
        <>
          <p>{options[0]?.trf_naam}</p>
          <RatePlanInfo ratePlan={options[0]} />
          <div className="mt-4"></div>
          <Cta
            text="Opzeggen"
            loading={loading}
            style="danger"
            onClick={removeOption}
            icon={<Trash2 size={14} />}
          />
        </>
      )}
    </div>
  );
};

export default AddOption;

import { Switch } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import { TarifService } from '../../../services/simcards/Tarifs';
import { TicketService } from '../../../services/tickets/Tickets';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import Typography from '../../typography/Typography';
import { ActionsService } from '../../../services/tickets/Actions';

export interface Props {
  simData: SimcardInterface;
  title?: string;
}

const Mpay: FC<Props> = ({ simData }) => {
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [features, setFeatures] = useState({});
  const actionsService = new ActionsService();

  const dispatch = useDispatch();

  const onChange = async (event, checked) => {
    setLoading(true);

    setFeatures((prev) => ({
      ...prev,
      [event]: checked,
    }));


    await actionsService.changeSimServices(companyId, simData.sim_id, event, checked);

    dispatch(
      addToast({
        description: [
          `Event ${checked ? 'inschakelen' : 'uitschakelen'}`,
          { event: event },
        ],
        position: 'bottomRight',
        style: 'success',
      })
    );

    setLoading(false);
  };

  const findFeature = (item: string) => {
    return simData?.sim_features?.find(
      ({ prx_sta_option }) => prx_sta_option === item
    );
  };

  useEffect(() => {
    const updatedFeatures = {
      voicemail: false,
      'm-pay': false,
    };
    simData?.sim_features?.forEach((feature) => {
      updatedFeatures[feature.prx_sta_option] = true;
    });

    setFeatures(updatedFeatures);
  }, [simData]);

  return (
    <div className="flex space-x-6">
      {Object.keys(features).map((item, i) => (
        <div key={item}>
          <Typography tag="span" type="bold">
            {item}
          </Typography>
          <div className="flex space-x-3 mb-5">
            <Typography tag="span" type="label">
              {features[item] ? 'Ingeschakeld' : 'Uitgeschakeld'}
            </Typography>
            <Switch
              checked={features[item]}
              onChange={(checked) => onChange(item, checked)}
              loading={loading}
              className={features[item] ? '' : 'bg-slate-200 dark:bg-slate-700'}
              id={item}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Mpay;

import React, { FC, SetStateAction } from 'react';
import Accordeon from '../../../components/accordeon/Accordeon';

import AddOption from '../../../components/forms/simcards/AddOption';
import ChangeTarif from '../../../components/forms/simcards/ChangeTarif';
import ConvertToPrepaid from '../../../components/forms/simcards/ConvertToPrepaid';
import ReActivate from '../../../components/forms/simcards/ReActivate';
import RequestPuk from '../../../components/forms/simcards/RequestPuk';
import SwapSimcard from '../../../components/forms/simcards/SwapSimcard';

import { useTranslation } from 'react-i18next';
import DisableSimcard from '../../../components/forms/simcards/DisableSimcard';
import Mpay from '../../../components/forms/simcards/Mpay';
import TempDisable from '../../../components/forms/simcards/TempDisable';
import { SimcardInterface } from '../../../interfaces/Simcard';

export interface props {
  simData: SimcardInterface;
  setSim: SetStateAction<SimcardInterface>;
}

const SimActions: FC<props> = ({ simData, setSim }) => {
  const { t } = useTranslation();

  console.log(simData);

  const panels = [
    {
      key: '1',
      label: t('Ruil simkaart'),
      children: (
        <SwapSimcard
          currentValue={simData.sim_unique_nr}
          simData={simData}
          setSim={setSim}
        />
      ),
    },
    {
      key: '2',
      label: t('Omschakelen naar Pay & Go'),
      children: <ConvertToPrepaid simData={simData} setSim={setSim} />,
    },
    {
      key: '3',
      label: t('Opties beheren'),
      children: <AddOption currentValue="" simData={simData} setSim={setSim} />,
    },
    {
      key: '4',
      label: t('Wijzig tariefplan'),
      children: (
        <ChangeTarif
          currentValue={simData.tariefplan ? simData.tariefplan.trf_naam : ''}
          currentTarifId={simData.sim_trf_id}
          simData={simData}
          setSim={setSim}
        />
      ),
    },
    {
      key: '5',
      label: t('Diensten'),
      children: <Mpay simData={simData} />,
    },
    {
      key: '6',
      label: t('Tijdelijk buiten dienst zetten'),
      children: <TempDisable simData={simData} setSim={setSim} />,
    },
    {
      key: '7',
      label: t('PUK-code aanvragen'),
      children: <RequestPuk simData={simData} setSim={setSim} />,
    },
  ];

  if (simData?.sim_limited_options !== 1) {
    panels.push(
      {
        key: '8',
        label: <span className=" text-red-700">{t('Nummer opzeggen')}</span>,
        children: <DisableSimcard simData={simData} setSim={setSim} />,
      });
  }

  return (
    <div>
      {simData.sim_isActive === 6 ? (
        <ReActivate simData={simData} setSim={setSim} />
      ) : (
        <Accordeon panels={panels} />
      )}
    </div>
  );
};

export default SimActions;

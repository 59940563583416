import React, { FC } from 'react';
import BranchForm from '../../../components/forms/branches/BranchForm';
import TextHeader from '../../../components/headers/TextHeader';

interface Props {
  data: any;
  callback: () => void;
}

const BranchCreateContainer: FC<Props> = ({ data, callback }) => {
  
  
  return (
    <div>
      <TextHeader title="Aanmaken" subtitle="Vestiging" />
      <BranchForm
        branch={{}}
        title={data.title}
        callback={callback}
      ></BranchForm>
    </div>
  );
};

export default BranchCreateContainer;

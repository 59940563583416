import React from 'react';
import Tabs from '../../../components/tabs/Tabs';
import ActivateSimBulk from './ActivateSimBulk';
import ActivateSimRequest from './ActivateSimRequest';

const SimActivationContainer = () => {
  const tabs = [
    {
      nav: 'Simkaart activeren',
      component: [<ActivateSimRequest />],
      key: 1,
    },
    {
      nav: 'Bulk activatie',
      component: [<ActivateSimBulk />],
      key: 2,
    },
  ];

  return (
    <>
      <Tabs tabs={tabs} />
    </>
  );
};

export default SimActivationContainer;

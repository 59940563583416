import type { TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Cta from '../../../components/buttons/Cta';
import UserSelect from '../../../components/users/UserSelect';
import { SimcardInterface } from '../../../interfaces/Simcard';
import SimcardSelect from '../../../organisms/simcards/SimcardSelect';
import TarifSelect from '../../../organisms/simcards/TarifSelect';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TarifService } from '../../../services/simcards/Tarifs';
import { ActionsService } from '../../../services/tickets/Actions';
import { clearAsides } from '../../../state/component/AsideSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { SimMapper } from '../../../utils/mappers/SimMapper';
import ActivateSimBulkForm from './ActivateSimBulkForm';

export interface Bulksim {
  key: number;
  simNr: string;
  simTarifId: string;
  simUserId?: number;
  error?: string;
}

type ColumnTypes = Exclude<TableProps<Bulksim>['columns'], undefined>;

const simcardService = new SimcardService();
const actionsService = new ActionsService();
const tarifService = new TarifService();

const createSimRecord = (
  values: Bulksim = { simNr: '', simTarifId: '', key: 1 }
): Bulksim => ({
  ...values,
});

const ActivateSimBulk: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [count, setCount] = useState(2);
  const [loading, setLoading] = useState(false);
  const [sims, setSims] = useState<SimcardInterface[]>([]);
  const [usedSims, setUsedSims] = useState<string[]>([]);
  const [tarifs, setTarifs] = useState(['']);
  const [dataSource, setDataSource] = useState<Bulksim[]>([createSimRecord()]);

  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  )!;

  const fetchSims = async () => {
    const sims = await simcardService.getInactiveSimcards(companyId);
    setSims(sims.sims as unknown as SimcardInterface[]);
  };

  const fetchTarifs = async () => {
    const companyVoiceTarifs = await tarifService.getCompanySpecif(
      companyId!,
      'Voice'
    );
    const { result: companyDataTarifs } =
      await tarifService.getTarrisFromCompanyContract(companyId!, 'Data');

    setTarifs({
      Voice: companyVoiceTarifs,
      Data: companyDataTarifs,
    });
  };

  useEffect(() => {
    fetchSims();
    fetchTarifs();
  }, [companyId]);

  const options = [
    ...(sims
      .filter((v) => !!v.sim_unique_nr && !usedSims.includes(v.sim_unique_nr))
      .map(SimMapper.mapSimToOptions) || []),
  ];

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: t('Simkaart') + '*',
      dataIndex: 'simNr',
      width: '25%',
      render: function (value, record, index) {
        return (
          <SimcardSelect
            value={value}
            status={'inactive'}
            includeESIM={false}
            options={options}
            onSelect={(value) => handleInput(value, 'simNr', index)}
            onClear={() => handleClear(index)}
          />
        );
      },
    },
    {
      title: t('Tariefplan') + '*',
      dataIndex: 'simTarifId',
      width: '40%',
      render: function (value, record, index) {
        return (
          <TarifSelect
            onSelect={(value) => handleInput(value, 'simTarifId', index)}
            defaultValue={undefined}
            tarifs={tarifs}
            detail={false}
          />
        );
      },
    },
    {
      title: t('Gebruiker'),
      dataIndex: 'simUserId',
      width: '28%',
      render: function (value, record, index) {
        return (
          <UserSelect
            handleSelect={(value) => handleInput(value, 'simUserId', index)}
            defaultValue={undefined}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length > 1 ? (
          <button
            className="flex justify-center items-center w-full"
            type="button"
            onClick={() => handleDelete(record, index)}
          >
            <Trash2 size={18} />
          </button>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = createSimRecord({ simNr: '', key: count, simTarifId: '' });
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleInput = (value: any, key: string, index: number) => {
    if (!value) return;
    setUsedSims((v) => [...v, value]);
    dataSource[index][key] = value;
  };

  const handleDelete = (record: Bulksim, index: number) => {
    const usedSim = usedSims.filter((v) => v === record.simNr);
    const newData = dataSource.filter((item) => item.key !== record.key);
    setUsedSims([...usedSim]);
    setDataSource(newData);
  };

  const handleClear = (index: number) => {
    const sim = dataSource[index].simNr;
    if (!sim) {
      return;
    }

    const newUsedSims = usedSims.filter((v) => v !== sim);
    setUsedSims(newUsedSims);
  };

  const columns = defaultColumns.map((col) => {
    return col;
  });

  function handleError() {
    let error = '';
    dataSource.forEach((item) => {
      if (!item.simTarifId) {
        error = 'Iedere simkaart heeft een tarief nodig';
      }
      if (item.simNr.length !== 13 || !item.simNr.startsWith('2100')) {
        error =
          'Een simkaart moet beginnen met 2100 en 13 cijfers bevatten of de optie eSIM moet gekozen zijn.';
      }
    });

    if (!error) {
      return;
    }

    setLoading(false);
    dispatch(
      addToast({
        description: t(error),
        position: 'bottomRight',
        style: 'error',
      })
    );

    return error;
  }

  const handleSubmit = async () => {
    setLoading(true);

    const error = handleError();
    if (error) {
      return;
    }

    try {
      await actionsService.bulkSimActivation(companyId, {
        sims: dataSource.map(SimMapper.mapSimForBulk),
      });
      setDataSource([]);
      dispatch(clearAsides());
      dispatch(
        addToast({
          description: t('Bulk activatie succesvol'),
          position: 'bottomRight',
          style: 'success',
        })
      );
    } catch (error) {
      console.error('Error processing the request:', error);
      dispatch(
        addToast({
          description: t('Er is iets misgelopen.'),
          position: 'bottomRight',
          style: 'error',
        })
      );
    }

    setLoading(false);
  };

  return (
    <div className="w-4xl">
      <Cta
        onClick={handleAdd}
        color="default"
        style={'small'}
        text="Toevoegen"
      />
      <div className="mb-5"></div>
      <ActivateSimBulkForm
        handleSubmit={handleSubmit}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default ActivateSimBulk;

import { Checkbox, DatePicker, DatePickerProps, Form, GetProp } from 'antd';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GroupsService } from '../../../services/groups/Groups';
import { ProjectsService } from '../../../services/project/Projects';
import { UsersService } from '../../../services/users/Users';
import { clearAside } from '../../../state/component/AsideSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { createUser, updateUser } from '../../../state/user/UserSlice';
import {
  CompanyModules,
  hasModule,
} from '../../../utils/auth/AuthorizationUtility';
import { Capitalize } from '../../../utils/text/Capitalize';
import FormButton from '../../buttons/FormButton';
import SelectBox from '../../inputs/selectbox/SelectBox';
import FormField from '../../inputs/text/FormField';
import ToggleSwitch from '../../inputs/toggle/ToggleSwitch';
import PopoverItem from '../../tags/PopoverQuestionMark';
import PopOverIcon from '../../tags/PopOverIcon';
import Typography from '../../typography/Typography';

type User = {
  user: any;
  title: string;
  callback?: (e: any) => void;
};

const EditUserForm: FC<User> = ({ user, title, callback }) => {
  const { t } = useTranslation();
  const service = new UsersService();
  const groupService = new GroupsService();
  const [form] = Form.useForm();
  const [userData, setUserData] = useState(user);
  const [onboarding, setOnboarding] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedCompany = useSelector(
    (state: RootState) => state.companies.selectedCompany
  );
  const modules = useSelector((state: RootState) => state.auth.modules);
  const dispatch = useDispatch();
  const [formState, setFormState] = useState<any>({});

  useEffect(() => {
    setOnboarding(false);

    const fetchData = async () => {
      await fetchOptions();
      setUserData(user);
      form.setFieldsValue(user);
    };

    fetchData();
  }, [user]);

  const fetchOptions = async () => {
    setLoading(true);
    const fetchedOptions = await groupService.getGroupNames(selectedCompany.id);

    const formattedOptions = fetchedOptions.map((option) => ({
      ...option,
      searchLabel: option.searchLabel || option.label,
    }));

    setOptions(formattedOptions);
    setLoading(false);
  };

  const handleSelect = (value: string, label: string) => {
    const groupId = value;
    const groupName = label.label;

    setUserData((prev) => ({
      ...prev,
      geb_groep_id: groupId,
      bedrijf_groep: {
        bedGr_id: groupId,
        bedGr_naam: groupName,
      },
    }));
  };

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setUserData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const onUpdate = async (values: any) => {
    const { geb_groep_id } = userData;

    const geb_isActive =
      userData.geb_isActive === true || userData.geb_isActive === 1 ? 1 : 2;
    const geb_isAdmin = geb_isActive === 1 ? !!userData.geb_isAdmin : false;

    const updatedValues = {
      geb_groep_id,
      ...values,
      geb_isActive: geb_isActive,
      geb_isAdmin: geb_isAdmin,
      geb_isRapport: !!userData.geb_isRapport,
      geb_role: geb_isAdmin
        ? 'admin'
        : geb_isActive === 1
          ? 'user'
          : 'employee',
    };

    await service.updateUser(selectedCompany.id, user.geb_id, updatedValues);

    dispatch(
      updateUser({
        id: user.geb_id,
        updatedData: {
          ...updatedValues,
          bedrijf_groep: userData.bedrijf_groep,
        },
      })
    );

    dispatch(
      addToast({
        description: 'De gebruiker is aangepast.',
        position: 'bottomRight',
        style: 'success',
      })
    );

    closeAside();
  };

  const onCreate = async (values: any) => {
    const { geb_groep_id, geb_isRapport, geb_isActive, geb_isAdmin } = userData;

    const updatedValues = {
      ...values,
      email: values.email === "" ? undefined : values.email === "null" ? null : values.email,
      geb_groep_id,
      geb_isRapport: geb_isRapport ?? false,
      geb_isActive: geb_isActive === 1 || geb_isActive === true ? 1 : 2,
      geb_isAdmin: geb_isActive ? (geb_isAdmin ?? false) : false,
      geb_role: geb_isAdmin && geb_isActive
        ? 'admin'
        : geb_isActive === 1 || geb_isActive === true
          ? 'user'
          : 'employee',
    };

    const newUser = await service.createUser(selectedCompany.id, updatedValues);

    dispatch(
      createUser({
        ...updatedValues,
        geb_id: newUser.userId,
        bedrijf_groep: userData.bedrijf_groep,
      })
    );

    dispatch(
      addToast({
        description: 'De gebruiker is aangemaakt.',
        position: 'bottomRight',
        style: 'success',
      })
    );

    if (callback) {
      callback(newUser);
    }

    closeAside();
  };

  const closeAside = () => {
    dispatch(clearAside(title));
  };

  return (
    <div>
      <Form
        labelAlign="left"
        layout="vertical"
        form={form}
        initialValues={user}
        onValuesChange={(_, allFields) => {
          setFormState(allFields);
        }}
        onFinish={Object.keys(user).length === 0 ? onCreate : onUpdate}
      >
        <div className="mt-5 grid grid-cols-3 gap-x-3">
          <div className="col-span-1">
            <FormField
              type="text"
              label="Naam"
              placeholder="John"
              name="geb_voornaam"
              form={form}
              isRequired={true}
            />
          </div>
          <div className="col-span-1">
            <FormField
              type="text"
              label="Familienaam"
              placeholder="Doe"
              name="geb_naam"
              form={form}
              isRequired={true}
            />
          </div>

          <div className="col-span-2">
            <FormField
              type="text"
              placeholder="john.doe@company.com"
              label="E-mailadres"
              name="email"
              form={form}
              rules={[{ required: userData.geb_isActive === 1 || userData.geb_isActive === true || userData.geb_isRapport, message: t('Een e-mailadres is verplicht voor een gebruiker.') }]}
            />
          </div>
          <div className="col-span-1 mt-auto pb-[24px]">
            <SelectBox
              options={options}
              defaultValue={
                userData.geb_groep_id
                  ? userData.bedrijf_groep?.bedGr_naam
                  : t('Geen groep')
              }
              onChange={handleSelect}
              loading={loading}
            />
          </div>
          
          <div className="col-span-3 pb-2">
            <Typography tag="h3" type="subtitle">{t('Opties')}</Typography>
          </div>
          <div className="col-span-3 my-1 flex">
            <ToggleSwitch
              label="Geef toegang tot het portaal"
              checked={
                userData.geb_isActive !== 1 && userData.geb_isActive !== true
                  ? false
                  : true
              }
              onChange={(event) => handleToggleChange(event)}
              name="geb_isActive"
            />
            <PopoverItem
              description={
                t('Door een gebruiker toegang te geven tot het plaform kan deze inloggen en gebruik maken van de functionaliteiten. Dit stuurt tevens een mail naar de gebruiker om zich te registeren indien dit nog niet gebeurd is.')
              }
              title={t("Gebruiker")}
            ></PopoverItem>
          </div>
          <div className="col-span-3 my-1 flex">
            <ToggleSwitch
              label="Administrator"
              checked={userData.geb_isAdmin}
              onChange={(event) => handleToggleChange(event)}
              name="geb_isAdmin"
              disabled={userData.geb_isActive !== 1 && userData.geb_isActive !== true}
            />
            <PopoverItem
              description={'Enkel gebruikers kunnen administrator zijn, administrators hebben volledige toegang op het portaal en kunnen deze beheren, zoals gebruikers toevoegen of verwijderen.'}
              title="Admin"
            ></PopoverItem>
          </div>
          {hasModule(modules, CompanyModules.RAPPORTAGE) && (
            <div className="col-span-3 my-1 flex">
              <ToggleSwitch
                label="Rapportage ontvangen"
                checked={userData.geb_isRapport}
                onChange={(event) => handleToggleChange(event)}
                name="geb_isRapport"
              />
              <PopoverItem
                description={'Als deze optie is aangevinkt ontvangt de gebruiker rapportage van het telefoon verbruik van alle gebruikers binnen het bedrijf per email.'}
                title="Rapportage ontvangen"
              ></PopoverItem>
            </div>
          )}
          <div className="col-span-3 pb-2">
            <p><span className="text-xs text-gray-500 flex">
              <PopOverIcon />
              {t("Bijkomende opties voor een gebruiker, hou uw muis over het icoontje met het vraagteken voor meer informatie.")}
              </span>
              </p>
          </div>
          <div className="col-span-3 my-2 gap-5">
            <div>
              <FormButton
                form={true}
                text={
                  Object.keys(user).length === 0
                    ? onboarding
                      ? 'Onboarden'
                      : 'Aanmaken'
                    : 'Opslaan'
                }
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditUserForm;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export class DateUtil {
  /**
   * Converts a local date (Date object) to UTC (Date object)
   */
  static localDateToUTC(date: Date): Date {
    const localDate = dayjs(date).startOf('day');
    return dayjs.utc(localDate.format('YYYY-MM-DD')).toDate();
  }

  /**
   * Converts a UTC date (Date object) to local (Date object)
   */
  static utcDateToLocal(date: Date): Date {
    const utcDate = dayjs.utc(date).startOf('day');
    return dayjs(utcDate.format('YYYY-MM-DD')).toDate();
  }

  /**
   * Converts a local datetime (Date object) to UTC (Date object)
   */
  static localDateTimeToUTC(date: Date): Date {
    return dayjs(date).utc().toDate();
  }

  /**
   * Converts a UTC datetime (Date object) to local (Date object)
   */
  static utcDateTimeToLocal(date: Date): Date {
    return dayjs.utc(date).local().toDate();
  }
}
  
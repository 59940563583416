import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import Cta from '../../buttons/Cta';
import { SimStatusTag } from '../../tags/StatusTag';
import { ActionsService } from '../../../services/tickets/Actions';
import { addToast } from '../../../state/toast/ToastSlice';

export interface Props {
  simData: SimcardInterface;
  setSim: any;
}

const ReActivate: FC<Props> = ({ simData, setSim }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const actionsService = new ActionsService();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const handleSubmit = async () => {
    setLoading(true);

    await actionsService.reactiveSimCard(companyId!, simData.sim_id);

    dispatch(
      addToast({
        description: "Ticket is aangemaakt en in behandeling",
        position: 'bottomRight',
        style: 'success',
      })
    );

    setLoading(false);
  };

  return (
    <div className="flex justify-center items-center">
      <div className="text-center font-bold">
        <Cta
          text="Opnieuw activeren"
          style="danger"
          onClick={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ReActivate;

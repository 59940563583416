import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import DateInput from '../../inputs/dates/DateInput';
import { SimStatusTag } from '../../tags/StatusTag';
import Typography from '../../typography/Typography';
import Form from '../Form';
import { ActionsService } from '../../../services/tickets/Actions';
import { DateUtil } from '../../../utils/dates/DateUtil';

export interface Props {
  simData: SimcardInterface;
  setSim: any;
}

const DisableSimcard: FC<Props> = ({ simData, setSim }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const actionsService = new ActionsService();
  const dispatch = useDispatch();

  const handleChange = (date) => {
    setDate(date);
  };

  const handleSubmit = async (e: Event) => {
    setLoading(true);

    const prop = {
      sim_isActive: SimStatusTag.REMOVE,
    };

    setSim((state: SimcardInterface) => ({
      ...state,
      ...prop,
    }));
    dispatch(patchSimcard({ props: prop, simId: simData.sim_id }));

    await actionsService.cancelNumber(companyId!, simData.sim_id, DateUtil.localDateToUTC(date.toDate()) );

    setLoading(false);

    dispatch(
      addToast({
        description: t(
          'Uw aanvraag voor het opzeggen van het gsm nummer is ingediend en wordt verwerkt. U kunt dit opvolgen via het bijhorende ticket.'
        ),
        position: 'bottomRight',
        style: 'success',
      })
    );
  };

  return (
    <Form
      buttonLabel="Opzeggen"
      handleSubmit={handleSubmit}
      gridSize="grid-cols-2"
      loading={loading}
      buttonStyle="danger"
    >
      <div className="block mt-2 mb-2">
        <Typography tag="label" type="label">
          informatie opzeggen nummer
        </Typography>
        <div className="mb-2"></div>
        <Typography tag="label" type="label">
          Datum
        </Typography>
        <div className="block">
          <DateInput onChange={handleChange} value={date} />
        </div>
      </div>
      <div className="mb-3"></div>
    </Form>
  );
};
export default DisableSimcard;

import { Popover } from 'antd';
import React, { FC } from 'react';
import PopOverIcon from './PopOverIcon';

type Props = {
  title?: string;
  description: string;
};

const PopoverItem: FC<Props> = ({ title, description }) => {
  return (
    <div>
      <Popover content={description} title={title}>
        <button
          data-popover-target="popover-description"
          data-popover-placement="bottom-end"
          type="button"
        >
          <PopOverIcon />
          <span className="sr-only">Show information</span>
        </button>
      </Popover>
    </div>
  );
};

export default PopoverItem;

import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Button, Upload, message } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../typography/Typography';

const { Dragger } = Upload;

type Props = {
  fileTypes?: string[];
  fileList: UploadFile[];
  onChange: (updatedFileList: UploadFile[]) => void;
  style?: "dragger" | "min";
  files?: number;
  hint?: string;
  description?: string;
  name?: string;
  classes?: string;
};

const FileUpload: FC<Props> = ({ fileTypes, fileList, onChange, style = "dragger", files = 5, hint, description }) => {
  const { t } = useTranslation();

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    fileList,
    onRemove: (file) => {
      const updatedFileList = fileList.filter((f) => f.uid !== file.uid);
      onChange(updatedFileList);
    },
    beforeUpload: (file) => {
      const isAllowedType = fileTypes ? fileTypes.includes(file.type) : true;
      if (!isAllowedType) {
        message.error(`Het bestandtype: ${file.type} wordt niet ondersteund.`);
        return Upload.LIST_IGNORE;
      }
      onChange([...fileList, file]);
      return false;
    },
  };

  return (
    <div>
      { style === "dragger" ? 
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        { description && <p className="ant-upload-text">
          {t(description)}
        </p> }
        { hint && <p className="ant-upload-hint">
          {t(
            hint
          )}
        </p>}
      </Dragger>
      : 
      <Upload {...uploadProps}>
        <Button className={uploadProps.className || ""} icon={<UploadOutlined />}>{t(description || "Klik om te uploaden")}</Button>
      </Upload>
    }
    </div>
  );
};

export default FileUpload;

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import SimcardSelect from '../../../organisms/simcards/SimcardSelect';
import { SimcardService } from '../../../services/simcards/Simcards';
import { ActionsService } from '../../../services/tickets/Actions';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { SimMapper } from '../../../utils/mappers/SimMapper';
import { SimStatusTag } from '../../tags/StatusTag';
import Typography from '../../typography/Typography';
import Form from '../Form';

type props = {
  currentValue: string;
  simData: SimcardInterface;
  setSim: any;
};

const simcardService = new SimcardService();

const SwapSimcard: FC<props> = ({ simData, setSim }) => {
  const [loading, setLoading] = useState(false);
  const [newSim, setNewSim] = useState('');
  const [error, setError] = useState('inactive');
  const [sims, setSims] = useState<SimcardInterface[]>([]);
  const [t] = useTranslation();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  )!;

  const actionService = new ActionsService();
  const dispatch = useDispatch();

  const fetchSims = async () => {
    const sims = await simcardService.getInactiveSimcards(companyId);
    setSims(sims.sims as unknown as SimcardInterface[]);
  };

  useEffect(() => {
    fetchSims();
  }, [companyId]);

  const handleChange = async (event) => {
    setNewSim(event);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);

    if (
      !newSim ||
      (newSim !== 'eSIM' &&
        (newSim.length !== 13 || !newSim.startsWith('2100')))
    ) {
      dispatch(
        addToast({
          description: t(
            'Een simkaart moet beginnen met 2100 en 13 cijfers bevatten of de optie eSIM moet gekozen zijn.'
          ),
          position: 'bottomRight',
          style: 'warning',
        })
      );
      setError('error');
      setLoading(false);
      return false;
    }

    setSim((state: SimcardInterface) => ({
      ...state,
      sim_isActive: SimStatusTag.SWAP_SIM,
    }));

    dispatch(
      patchSimcard({
        props: { sim_isActive: SimStatusTag.SWAP_SIM },
        simId: simData.sim_id,
      })
    );

    await actionService.swapSimCard(
      companyId!,
      newSim === 'eSIM' ? undefined : newSim,
      simData.sim_id,
      newSim === 'eSIM'
    );

    dispatch(
      addToast({
        description: [
          'Swap sim',
          {
            sim_unique_nr: simData.sim_unique_nr,
          },
        ],
        position: 'bottomRight',
        style: 'success',
      })
    );
    setLoading(false);
    setError('success');
    setNewSim('');
  };

  return (
    <div>
      <Form
        buttonLabel="Indienen"
        handleSubmit={handleSubmit}
        gridSize="grid-cols-2"
        loading={loading}
      >
        <Typography tag="label" type="label">
          Selecteer nieuwe simkaart
        </Typography>
        <SimcardSelect
          onSelect={handleChange}
          defaultValue={newSim}
          options={[...sims.map(SimMapper.mapSimToOptions), { label: "eSIM", value: "eSIM" }]}
          error={error}
          status={'inactive'}
          includeESIM={true} // Pass prop to enable eSIM in SelectSimcard
        />
      </Form>
    </div>
  );
};

export default SwapSimcard;

import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import SelectTarif from '../../../organisms/simcards/SelectTarif';
import { TarifService } from '../../../services/simcards/Tarifs';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { SimStatusTag } from '../../tags/StatusTag';
import Typography from '../../typography/Typography';
import Form from '../Form';
import { ActionsService } from '../../../services/tickets/Actions';

type props = {
  currentTarifId: number;
  currentValue: string;
  simData: SimcardInterface;
  setSim: any;
};

const ChangeTarif: FC<props> = ({
  currentTarifId,
  currentValue,
  simData,
  setSim,
}) => {
  const [tarif, setTarif] = useState(currentTarifId);
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const actionsService = new ActionsService();
  const service = new TarifService();
  const dispatch = useDispatch();

  const handleChange = async (value, option) => {
    setTarif(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    const selectedTarif = await service.getTarif(tarif, companyId);
    const prop = {
      tariefplan: selectedTarif,
      trf_id: currentTarifId,
      sim_isActive: SimStatusTag.REQUEST_TARIFF,
    };

    setSim((state: SimcardInterface) => ({
      ...state,
      ...prop,
    }));

    dispatch(patchSimcard({ props: prop, simId: simData.sim_id }));
    await actionsService.changeTarif(companyId!, simData.sim_id, tarif);

    dispatch(
      addToast({
        description: 'Ticket aangemaakt',
        position: 'bottomRight',
        style: 'success',
      })
    );

    setLoading(false);
  };

  return (
    <div>
      <Form
        buttonLabel="Indienen"
        handleSubmit={handleSubmit}
        gridSize="grid-cols-2"
        loading={loading}
      >
        <Typography tag="label" type="label">
          Selecteer nieuw tariefplan
        </Typography>
        <SelectTarif
          onSelect={(value, option) => handleChange(value, option)}
          defaultValue={currentValue}
          type="Voice,Data"
        />
      </Form>
    </div>
  );
};

export default ChangeTarif;

import React, { ChangeEvent, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardService } from '../../../services/simcards/Simcards';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';

import { XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Box from '../../../components/boxes/Box';
import Cta from '../../../components/buttons/Cta';
import LoadingSpinner from '../../../components/buttons/LoadingSpinner';
import InputField from '../../../components/inputs/text/InputField';
import Tabs from '../../../components/tabs/Tabs';
import StatusTag from '../../../components/tags/StatusTag';
import Typography from '../../../components/typography/Typography';
import RatePlanInfo from '../../../components/typography/sim/RatePlanInfo';
import UserSelect from '../../../components/users/UserSelect';
import { addToast } from '../../../state/toast/ToastSlice';
import SimActions from './SimActions';
import SimTickets from './SimTickets';
import SimUsagePerUser from './SimUsagePerUser';
import SimUserUsage from './SimUserUsage';

interface SimDetailContainerProps {
  data: any;
}

const SimDetailContainer = ({ data }) => {
  const { t } = useTranslation();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new SimcardService();
  const simcardService = new SimcardService();
  const [loading, setLoading] = useState(true);
  const [sim, setSim] = useState(undefined);
  const [force, forceUpdate] = useReducer((x) => x + 1, 0);
  const dispatch = useDispatch();

  useEffect(() => {
    setSim(data.sim);
    setLoading(false);
  }, [data.sim]);

  if (!sim) {
    return <LoadingSpinner />;
  }

  const tabs = [
    {
      nav: t('Acties'),
      component: [<SimActions simData={data.sim} setSim={setSim} />],
    },
    {
      nav: t('Tickets'),
      component: [<SimTickets simId={data.sim.sim_id} />],
    },
    {
      nav: 'Maandverbruik',
      component: [<SimUserUsage phoneNumbers={[data.sim.sim_gsm_nr]} />],
    },
    {
      nav: 'Verbruik 12m',
      component: [
        <SimUsagePerUser
          key={1}
          props={{
            phoneNumbers: [data.sim.sim_gsm_nr],
          }}
        />,
      ],
    },
  ];

  const changeInput = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, value } = event.target;

    if (sim[name] !== value) {
      setSim((prev) => ({
        ...prev,
        [name]: value,
      }));
      changedata({ [name]: value });

      await service.updateSimcard(
        data.sim.sim_id,
        {
          [name]: value,
        },
        companyId
      );

      dispatch(
        addToast({
          description: 'Het veld is aangepast.',
          position: 'bottomRight',
          style: 'success',
        })
      );
    }
  };

  const changedata = (prop: object) => {
    dispatch(patchSimcard({ props: prop, simId: data.sim.sim_id }));
  };

  const handleSelect = async (value: string, label: string) => {
    simcardService.updateOrCreateProximusSim(
      {
        sim_unique_nr: data.sim.sim_unique_nr,
        sim_user_id: value,
      },
      companyId
    );

    dispatch(
      patchSimcard({
        props: { sim_user_id: value, sim_user: label.label },
        simId: data.sim.sim_id,
      })
    );

    dispatch(
      addToast({
        description: 'De gebruiker is gewijzigd.',
        position: 'bottomRight',
        style: 'success',
      })
    );
  };

  const handleRemoveUser = async () => {
    setLoading(true);
    try {
      simcardService.updateOrCreateProximusSim(
        {
          sim_unique_nr: data.sim.sim_unique_nr,
          sim_user_id: null,
        },
        companyId
      );

      dispatch(
        patchSimcard({
          props: { sim_user_id: null, sim_user: null },
          simId: data.sim.sim_id,
        })
      );

      forceUpdate();

      dispatch(
        addToast({
          description: 'Gebruiker verwijderd van de simkaart.',
          position: 'bottomRight',
          style: 'success',
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error removing user from sim:', error);
    }
  };

  return (
    <div key={data.sim.sim_id}>
      <Box gridSize="grid-5" type="borderedTiny" size="noSize">
        <div className="px-5 py-4 col-span-1">
          <Typography tag="span" type="label">
            Simkaart
          </Typography>
          <div className="block">
            <Typography tag="span" type="label">
              {data.sim.sim_unique_nr ?? 'eSim'}
            </Typography>
          </div>
        </div>

        <div className="px-5 py-4 col-span-2">
          <Typography tag="span" type="label">
            Telefoonnummer
          </Typography>
          <div className="block">
            <Typography tag="span" type="label">
              {data.sim.sim_gsm_nr ?? '/'}
            </Typography>
          </div>
        </div>

        <div className="px-5 py-4 col-span-2">
          <StatusTag label={sim.sim_isActive} />
        </div>
      </Box>

      <div className="mb-3"></div>

      <Box gridSize="grid-1" type="borderedTiny" size="noSize">
        <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
          <div className="col-span-2">
            <Typography tag="span" type="default">
              Gebruiker
            </Typography>
          </div>
          <div className="col-span-2">
            {/* <Typography tag="span" type="default">
              <InputField
                type="text"
                onBlur={changeInput}
                name="sim_user"
                value={data.sim.sim_user}
                defaultValue={data.sim.sim_user || ""}
                placeholder={t("Gebruiker toekennen")}
              />
            </Typography> */}
            <UserSelect
              key={force}
              handleSelect={handleSelect}
              defaultValue={data.sim.sim_user_id ?? 'Selecteer gebruiker'}
              loading={loading}
            />
          </div>
          {data.sim.sim_user_email ? (
            <div>
              <Cta
                text=""
                loading={loading}
                style="dangernbg"
                onClick={handleRemoveUser}
                icon={<XCircle size={18} />}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
          <div className="col-span-2">
            <Typography tag="span" type="default">
              Tariefplan
            </Typography>
          </div>
          <div className="col-span-3">
            {data.sim.sim_trf_id ? (
              <>
                <Typography tag="span" type="default">
                  {data.sim.tariefplan?.trf_naam}
                </Typography>
                <RatePlanInfo ratePlan={data.sim.tariefplan} />
              </>
            ) : (
              ''
            )}
          </div>
        </div>

        {data.sim.sim_options?.length > 0 ? (
          <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
            <div className="col-span-2">
              <Typography tag="span" type="default">
                Actieve optie
              </Typography>
            </div>
            <div className="col-span-3">
              <Typography tag="span" type="default">
                {data.sim.sim_options[0]?.trf_naam}
              </Typography>
              <RatePlanInfo ratePlan={data.sim.sim_options[0]} />
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
          <div className="col-span-2">
            <Typography tag="span" type="default">
              Afdeling
            </Typography>
          </div>
          <div className="col-span-2">
            <Typography tag="span" type="default">
              <InputField
                type="text"
                onBlur={changeInput}
                name="sim_label"
                value={data.sim.sim_label}
                defaultValue={data.sim.sim_label || ''}
                placeholder={t('Label voor simkaart')}
              />
            </Typography>
          </div>
        </div>
        <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
          <div className="col-span-2">
            <Typography tag="span" type="default">
              Nota
            </Typography>
          </div>
          <div className="col-span-2">
            <Typography tag="span" type="default">
              <InputField
                type="text"
                onBlur={changeInput}
                name="sim_nota"
                value={data.sim.sim_nota}
                defaultValue={data.sim.sim_nota || ''}
                placeholder={t('Nota voor simkaart')}
              />
            </Typography>
          </div>
        </div>
      </Box>
      {data.sim.sim_unique_nr ? (
        <Tabs tabs={tabs} />
      ) : (
        <p className="mt-3 opacity-80">
          Sim acties zullen hier beschikbaar zijn wanneer de simkaartnummer
          gekoppeld is.
        </p>
      )}
    </div>
  );
};

export default SimDetailContainer;

import { AutoComplete } from 'antd';
import React, { FC } from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Option } from '../../utils/mappers/SimMapper';

type OptionType = { label: any; value: string };

type props = {
  onSelect: (value: string, option: OptionType | OptionType[]) => void;
  defaultValue?: string;
  status: string;
  value?: string;
  error?: '' | 'error' | 'warning' | undefined;
  disabled?: boolean;
  includeESIM?: boolean;
  onClear?: (value: string) => void;
  options: Option[];
};

const SimcardSelect: FC<props> = ({
  onSelect,
  defaultValue,
  value,
  disabled,
  includeESIM = false,
  options,
  onClear,
}) => {
  const [t] = useTranslation();

  return (
    <AutoComplete
      showSearch={true}
      defaultValue={defaultValue}
      value={defaultValue}
      style={{ width: '100%' }}
      onChange={onSelect}
      onSelect={onSelect}
      onClear={onClear}
      optionFilterProp="label"
      allowClear={{ clearIcon: <X size={14} /> }}
      options={options}
      filterOption={(inputValue, option) => {
        return (
          option!.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        );
      }}
      disabled={disabled}
    />
  );
};

export default SimcardSelect;

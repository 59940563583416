import { Select } from 'antd';
import React, { FC } from 'react';
import RatePlanInfo from '../../components/typography/sim/RatePlanInfo';
import { Tariefplan } from '../../interfaces/Simcard';
import CurrencyFormatter from '../../utils/text/CurrencyFormatter';

interface SelectTarif {
  tarifs: Tariefplan[number];
  onSelect: (value: string) => void;
  defaultValue: any;
  detail?: boolean;
  error?: '' | 'error' | 'warning';
}

const TarifSelect: FC<SelectTarif> = ({
  tarifs,
  onSelect,
  defaultValue,
  detail = true,
  error,
}) => {
  if (!tarifs) {
    return <div>...loading</div>;
  }

  return (
    <Select
      showSearch
      defaultValue={defaultValue}
      style={{ width: '100%' }}
      onChange={onSelect}
      optionFilterProp="label"
      optionLabelProp="searchLabel"
      status={`${error ? 'error' : ''}`}
      id="5"
      options={Object.keys(tarifs).map((key: string) => ({
        label: (
          <span
            key={key}
            className="underline font-bold text-slate-950 dark:text-slate-200"
          >
            {key}
          </span>
        ),
        title: key,
        options: Object.keys(tarifs[key]).map((trf) => ({
          label: (
            <div key={key} className="flex justify-between items-center">
              <div className="flex flex-col">
                <p className="align-top ">{tarifs[key][trf].trf_naam}</p>
              </div>
              {tarifs[key][trf].price && detail ? (
                <div className="flex flex-col justify-end text-right">
                  <p>
                    <CurrencyFormatter price={tarifs[key][trf].price} />
                  </p>
                  <RatePlanInfo ratePlan={tarifs[key][trf]} />
                </div>
              ) : (
                ''
              )}
            </div>
          ),
          searchLabel: tarifs[key][trf].trf_naam,
          value: tarifs[key][trf].trf_id,
        })),
      }))}
    />
  );
};

export default TarifSelect;

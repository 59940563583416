import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Upload } from 'antd';
import Papa from 'papaparse';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Table from '../../../components/tables/Table';
import { UsersService } from '../../../services/users/Users';
import ListComponent from '../../../utils/ListComponent';
import Cta from '../../buttons/Cta';
import TextHeader from '../../headers/TextHeader';
import TableItem from '../../tables/TableItem';
import Typography from '../../typography/Typography';

const { Dragger } = Upload;

const ImportUsersForm = () => {
  const [users, setUsers] = useState('');
  const [transformedUsers, setTransformedUsers] = useState([{}]);
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState('');
  const { t } = useTranslation();

  const service = new UsersService();
  const dispatch = useDispatch();

  const config = {
    headers: [
      {
        name: 'naam',
        inputName: 'Naam',
        required: true,
        requiredError: function (headerName: string) {
          return `${headerName} is verplicht.`;
        },
        headerError: function () {
          return "Header 'naam' is fout of niet toegevoegd.";
        },
      },
      {
        name: 'familienaam',
        inputName: 'Familienaam',
        required: false,
      },
      {
        name: 'email',
        inputName: 'email',
        unique: true,
        uniqueError: function (headerName: string) {
          return 'E-mail is niet uniek';
        },
        validate: function (email: string) {
          return isEmailValid(email);
        },
        validateError: function (headerName: string) {
          return `${headerName} is niet toegelaten of het e-mailadres is ongeldig`;
        },
        headerError: function () {
          return "Header 'email' is fout of niet toegevoegd.";
        },
      },
    ],
  };

  const isEmailValid = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const props: UploadProps = {
    name: 'file',
    beforeUpload: (file) => {
      const handleFile = async () => {
        const reader = new FileReader();
        const validate = await validateUserData(file);

        if (validate === 'error') {
          return;
        }

        setErrors('');

        reader.onload = (e) => {
          Papa.parse(e.target.result, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              setUsers(results.data);
              
            },
          });
        };
        reader.readAsText(file);
      };
      handleFile();
      return false;
    },
  };

  const validateUserData = (file: any) => {
    if (file.type != 'text/csv') {
      setErrors([
        {
          message: t('Bestand is ongeldig', {
            filetype: 'csv',
            uploadedFileType: file.type,
          }),
        },
      ]);
      return 'error';
    }

    // const validate = CSVFileValidator(file, config)
    //   .then((csvData) => {
    //     if (csvData.inValidData.length > 10) {
    //       setErrors([{ message: t("Bestand is niet correct opgemaakt.") }]);
    //       return "error";
    //     }
    //     if (csvData.inValidData.length > 0) {
    //       setErrors(csvData.inValidData);
    //       return "error";
    //     }
    //   })
    //   .catch((err) => {
    //     return err;
    //   });

    // return validate;
  };

  const submitUsers = async () => {
    users.map((user) => {
      setTransformedUsers((prevArray) => [
        ...prevArray,
        {
          geb_voornaam: user.naam,
          geb_naam: user.familienaam,
        },
      ]);
    });
    
    // await service.createUsers(users);
  };

  const tableHeaders: object = ['Naam', 'Familienaam', 'E-mailadres'];

  return (
    <div>
      <TextHeader
        title="Importeer gebruikers"
        subtitle="Importeer gebruikers uit een csv bestand."
      />
      <div className="mt-5 py-5">
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {t(
              'Klik hier of sleep een bestand naar dit gebied om te uploaden.'
            )}
          </p>
          <p className="ant-upload-hint">
            {t('Enkel een correct csv bestand kan verwerkt worden')}
          </p>
        </Dragger>
      </div>

      {errors ? (
        <ListComponent
          data={errors}
          renderItem={(error) => (
            <Typography tag="p" type="label" color="danger">
              {error.message}
            </Typography>
          )}
        />
      ) : (
        ''
      )}

      <div className="mt-3">
        <Table header={tableHeaders}>
          {users ? (
            <ListComponent
              data={users}
              renderItem={(user) => (
                <tr>
                  <TableItem>{user.naam}</TableItem>
                  <TableItem>{user.familienaam}</TableItem>
                  <TableItem>{user.email}</TableItem>
                </tr>
              )}
            />
          ) : (
            <tr>
              <TableItem>{'Wachten op data'}</TableItem>
            </tr>
          )}
        </Table>
      </div>
      {users ? (
        <div>
          <Cta onClick={submitUsers} text="Toevoegen" />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ImportUsersForm;

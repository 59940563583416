import React, { FC, useEffect, useState } from 'react';
import { User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeThumb from '../../components/EmployeeThumb';
import Box from '../../components/boxes/Box';
import ChatBubble from '../../components/boxes/ChatBubble';
import LoadingSpinner from '../../components/buttons/LoadingSpinner';
import CommentForm from '../../components/forms/ticket/CommentForm';
import Tag from '../../components/tags/Tag';
import DateFormatted from '../../components/typography/Date';
import Time from '../../components/typography/Time';
import Typography from '../../components/typography/Typography';
import { TicketService } from '../../services/tickets/Tickets';
import { RootState } from '../../state/store';
import { addToast } from '../../state/toast/ToastSlice';

export interface Props {
  data: {
    ticket: any;
  };
}

const TicketDetailContainer: FC<Props> = ({
  data: { ticket: ticketState },
}) => {
  const { t } = useTranslation();
  const service = new TicketService();
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState<Object[]>([]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const email: string = useSelector((state: RootState) => state.profile.email);
  const user = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    setTicket({});
    setComments([]);

    const fetchTicketData = async () => {
      try {
        const { ticket } = await service.getTicket(ticketState.tic_id, companyId);
        const { comments } = await service.getComments(ticketState.tic_id, companyId);
        setTicket(ticket);
        setComments(comments);
      } catch (error) {
        console.error('Error fetching ticket:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTicketData();
  }, [ticketState.tic_id, companyId]);

  const handleCommentSubmit = async (body: string) => {
    try {
      const comment = {
        id: Math.random().toString(36).substr(2, 9),
        com_geb_naam: "MM",
        com_commentaar: body,
        created_at: new Date().toISOString(),
        com_geb_id: user.userId
      };

      setComments([...comments, comment]);

      await service.postComment(ticketState.tic_id, companyId, {
        body: body,
        author_id: ticket.gebruiker.geb_id,
      });
    } catch (error) {
      console.error('Error submitting comment:', error);
      dispatch(
        addToast({
          description: 'Failed to add comment. Please try again.',
          position: 'bottomRight',
          style: 'error',
        })
      );
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : ticket ? (
        <>
          <Box gridSize="grid-4" type="borderedTiny" size="noSize">
            <div className="col-span-4 px-6 py-4 grid grid-cols-4 items-center">
              <Typography tag="p" type="title">
                Ticket: #{ticket.tic_id}
              </Typography>
            </div>
            <div className="col-span-4 px-6 mb-4">
              <Typography tag="p" type="semibold">
                {ticket.tic_onderwerp}&nbsp;
              </Typography>
              <Typography tag="p" type="default">
                {ticket.tic_omschrijving}
              </Typography>
            </div>
          </Box>

          <div className="mb-2"></div>

          <Box gridSize="grid-4" type="borderedTiny" size="noSize">
            <div className="col-span-4 px-6 py-2">
              <ul className="flex flex-wrap">
                <li className="mr-4">
                  <Tag
                    label={`${ticket?.gebruiker.geb_naam} ${ticket?.gebruiker.geb_voornaam}`}
                    style="neutral"
                    icon={<User size={14} />}
                    lowercase={1}
                  />
                </li>
                <li className="mr-4">
                  <Time icon={1} time={ticket.created_at} />
                </li>
                <li>
                  <DateFormatted icon={1} date={ticket.created_at} />
                </li>
              </ul>
            </div>
          </Box>
          <div className="mb-2"></div>

          {/* <Box gridSize="grid-4" type="borderedTiny" size="noSize"> */}
          <div className="md:flex md:justify-between w-full items-center rounded-md border dark:border-slate-800 px-6">
            <div className="my-4 md:my-2 flex items-center md:justify-center gap-2">
              <Typography tag="span" type="label">
                Behandelaar
              </Typography>
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  {ticket?.assignedEmployee.med_naam ? (
                    <EmployeeThumb
                    image={ticket?.assignedEmployee.med_foto}
                      name={ticket?.assignedEmployee.med_naam}
                      size={8}
                    />
                  ) : (
                    t('Niet toegewezen')
                  )}
                </div>
              </div>
            </div>
            <div className="my-4 md:my-2 flex gap-2 items-center md:justify-center">
              <Typography tag="span" type="label">
                Afdeling
              </Typography>
              {ticket.tic_afdeling ? (
                <Tag
                  style="muted"
                  label={ticket.tic_afdeling}
                />
              ) : (
                ' /'
              )}
            </div>
            <div className="my-4 md:my-2 flex gap-2 items-center md:justify-center">
              <Typography tag="span" type="label">
                Status
              </Typography>
              <Tag
                style={ticket?.tic_status}
                label={ticket?.tic_status}
              />
            </div>
          </div>

          <div className="mt-2">
            <Box gridSize="grid-1" type="borderedTiny">
              <div className="py-10 max-h-[70vh] lg:max-h-[50vh] overflow-auto">
                {comments?.map((item, index) => {
                  
                  const currentDate = new Date(
                    item.created_at
                  ).toLocaleDateString();

                  const previousDate =
                    index > 0
                      ? new Date(
                        comments[index - 1].created_at
                      ).toLocaleDateString()
                      : null;

                  return (
                    <div key={item.id}>
                      {currentDate !== previousDate && (
                        <div className="flex justify-center text-center">
                          <Tag style={'muted'} label={currentDate} />
                        </div>
                      )}
                      <ChatBubble
                        message={item.com_commentaar}
                        author={item.com_geb_naam}
                        name={item.com_geb_naam}
                        time={item.created_at}
                        isAuthor={user.userId === item.com_geb_id}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="pt-5 px-4 mb-4">
                <CommentForm onSubmit={handleCommentSubmit} />
              </div>
            </Box>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default TicketDetailContainer;

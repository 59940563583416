import React, { FC, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import Cta from '../../buttons/Cta';
import { SimStatusTag } from '../../tags/StatusTag';
import Typography from '../../typography/Typography';
import { ActionsService } from '../../../services/tickets/Actions';

export interface Props {
  simData: SimcardInterface;
  setSim: any;
}

const RequestPuk: FC<Props> = ({ simData, setSim }) => {
  const [loading, setLoading] = useState(false);
  const actionsService = new ActionsService();
  const dispatch = useDispatch();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const handleClick = async () => {
    setLoading(true);

    const prop = {
      sim_isActive: SimStatusTag.PUK_REQUEST,
    };

    setSim((state: SimcardInterface) => ({
      ...state,
      ...prop,
    }));
    dispatch(patchSimcard({ props: prop, simId: simData.sim_id }));

    await actionsService.requestPuk(companyId!, simData.sim_id);

    dispatch(
      addToast({
        description: 'Ticket aangemaakt',
        position: 'bottomRight',
        style: 'success',
      })
    );

    setLoading(false);
  };

  return (
    <div>
      <Typography type="label" tag="span">
        PIN-code vergeten of simkaart geblokkeerd?
      </Typography>
      <Cta text="PUK-code aanvragen" onClick={handleClick} loading={loading} />
    </div>
  );
};

export default RequestPuk;

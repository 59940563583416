import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TicketService } from '../../../services/tickets/Tickets';
import { clearAsides } from '../../../state/component/AsideSlice';
import { setCurrentProject } from '../../../state/project/ProjectSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';

import SimOnboardingForm, { SimRequestInput } from '../../../components/forms/users/SimOnboardingForm';
import { ActionsService } from '../../../services/tickets/Actions';
import { DateUtil } from '../../../utils/dates/DateUtil';

const ActivateSimRequest: FC = (props) => {
  const [loading, setLoading] = useState(false);
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  ) as number;
  const actionsService = new ActionsService();

  let userId: number | undefined = undefined;
  if (props?.data?.task?.metadata[0]?.value) {
    userId 
        = parseInt(props.data?.task.metadata[0]?.value);
  }
  

  const dispatch = useDispatch();

  const handleSubmit = async (data: SimRequestInput) => {
    setLoading(true);

    try {

      await actionsService.transferNumber(companyId!, {
        userId: data.userId,
        isEsim: !!data.isEsim,
        isBudgetmanager: !!data.isBudgetmanager,
        isTransfer: data.transferType === "Overdracht nummer",
        phoneNumber: data.gsmNumber,
        tarifPlan: data.tarifId,
        branch: data.branch,
        transferDate: DateUtil.localDateToUTC(new Date(data.date)).toISOString(),
        loaFile: data.loaFile,
        operator: data.operator,
        paymentForm: data.subscriptionType,
        simNumber: data.sim,
        budgetFile: data.intekeningsFile,
        proximusFile: data.proximusTransferFile,
        idFile: data.idFile
      })

      dispatch(
        addToast({
          description: 'De aanvraag is succesvol ingediend.',
          position: 'bottomRight',
          style: 'success',
        })
      );

      dispatch(setCurrentProject(project));
      dispatch(clearAsides());
    } catch (error) {
      console.error('Error processing the request:', error);

      dispatch(
        addToast({
          description:
            'Er is een fout opgetreden bij het indienen van de aanvraag, kijk de gegevens na en probeer opnieuw. Moest het vervolgens nog niet lukken, neem dan contact op met de helpdesk.',
          position: 'bottomRight',
          style: 'error',
        })
      );
    }

    setLoading(false);
  };

  return (
    <div>
      <SimOnboardingForm onSubmit={handleSubmit} loading={loading} userId={userId} />
    </div>
  );
};

export default ActivateSimRequest;

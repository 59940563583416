import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  label: number;
};

export enum SimStatusTag {
  ACTIEF = 1,
  PUK_REQUEST = 2,
  SWAP_SIM = 3,
  REQUEST_TARIFF = 4,
  PROCESSED = 5,
  TEMP_DISABLED = 6,
  SWAP_PAYNGO = 7,
  REMOVE = 8,
  ADD_OPTION = 9,
  NIET_ACTIEF = 0
}

const StatusTag: FC<Props> = ({ label }) => {
  const { t } = useTranslation();

  const renderStyle = (style: number) => {
    switch (style) {
      case 1:
        return 'bg-green-100 text-green-700 rounded-full';
      case 0:
        return 'bg-red-100 text-red-700 rounded-full';
      default:
        return 'bg-orange-50 text-orange-400 rounded-full';
    }
  };

  const renderText = (status: SimStatusTag) => {
    switch (status) {
      case SimStatusTag.ACTIEF:
        return 'Actief';
      case SimStatusTag.PUK_REQUEST:
        return 'Puk-code aanvragen';
      case SimStatusTag.SWAP_SIM:
        return 'Ruil Simkaart';
      case SimStatusTag.REQUEST_TARIFF:
        return 'Tariefplan aanvragen';
      case SimStatusTag.PROCESSED:
        return 'Wordt verwerkt';
      case SimStatusTag.TEMP_DISABLED:
        return 'Tijdelijk buiten dienst';
      case SimStatusTag.SWAP_PAYNGO:
        return 'Omschakelen Pay&Go';
      case SimStatusTag.REMOVE:
        return 'Opzeggen';
      case SimStatusTag.ADD_OPTION:
        return 'Toevoegen optie';
      case SimStatusTag.NIET_ACTIEF:
      default:
        return 'Activeren';
    }
  };

  return (
    <span
      className={`${renderStyle(label)} text-xs font-bold uppercase px-3 py-1`}
    >
      {t(renderText(label))}
    </span>
  );
};

export default StatusTag;

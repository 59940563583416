import dayjs from 'dayjs';
import React, { FC, FormEvent, SetStateAction, useState } from 'react';
import DateInput from '../../inputs/dates/DateInput';
import Typography from '../../typography/Typography';
import Form from '../Form';

import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { SimStatusTag } from '../../tags/StatusTag';
import { ActionsService } from '../../../services/tickets/Actions';
import { t } from 'i18next';
import { DateUtil } from '../../../utils/dates/DateUtil';

type props = {
  simData: SimcardInterface;
  setSim: SetStateAction<SimcardInterface>;
};

const ConvertToPrepaid: FC<props> = ({ simData }) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const actionsService = new ActionsService();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const dispatch = useDispatch();

  const onChange = (date: Date) => {
    setDate(date);
  };

  const dayjsdate = dayjs(date);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(true);

    const currentDate = DateUtil.localDateToUTC(date);
    
    const weekend = [0, 6].includes(dayjs(currentDate).day());
    if (weekend) {
      dispatch(
        addToast({
          description: t('Kan niet op weekend dagen worden gewijzigd'),
          position: 'bottomRight',
          style: 'error',
         })
      );
      return;
    }

    const prop = {
      sim_status: 'Omschakeling Pay&Go',
    };

    // setSim((state: SimcardInterface) => ({
    //   ...state,
    //   sim_isActive: SimStatusTag.SWAP_PAYNGO,
    // }));

    dispatch(
      patchSimcard({
        props: { sim_isActive: SimStatusTag.SWAP_PAYNGO },
        simId: simData.sim_id,
      })
    );

    await actionsService.transferPayAndGo(companyId!, simData.sim_id, currentDate);
   
    dispatch(
      addToast({
        description: [
          'Omschakelen naar Pay&Go',
          {
            sim_unique_nr: simData.sim_unique_nr,
          },
        ],
        position: 'bottomRight',
        style: 'success',
      })
    );

    setLoading(false);
  };

  return (
    <>
      <Typography tag="span" type="label" addClass="mb-5 block">
        swap to prepaid
      </Typography>

      <Typography tag="span" type="label" addClass="block">
        Vanaf welke datum?
      </Typography>
      <Form
        buttonLabel="Indienen"
        handleSubmit={handleSubmit}
        gridSize="grid-cols-2"
        loading={loading}
      >
        <DateInput onChange={onChange} value={dayjsdate} />
        <div className="mb-2"></div>
      </Form>
    </>
  );
};

export default ConvertToPrepaid;

import { ConfigProvider, Form, FormInstance, FormItemProps } from 'antd';
import React, { ChangeEvent, FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';

interface FormFieldProps {
  type: 'text' | 'number' | 'email' | 'password' | 'search' | 'textarea'; // Add "textarea" type
  label?: string;
  info?: string;
  value?: string;
  name: string;
  style?: string;
  placeholder: string;
  form: FormInstance<any>; // Form instance from Ant Design
  isRequired?: boolean;
  className?: string; // Custom class name
  rules?: FormItemProps['rules'];
}


const FormField: FC<FormFieldProps> = ({
  type,
  label,
  name,
  style = 'default',
  info,
  placeholder,
  form,
  isRequired = false,
  className = '',
  rules = [],
}) => {
  const { t } = useTranslation();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const styles: Record<string, string> = {
    default:
      'border border-gray-300 dark:border-slate-600 dark:text-gray-300 text-sm rounded-lg block w-full px-2.5 py-1.5 bg-transparent dark:bg-gray-900 focus:outline-blue-700',
    transparent:
      'bg-transparent mb-2 border-b border-0 py-3 w-full rounded-none px-6 dark:border-gray-700',
  };

  if (isRequired) {
    rules.push({ required: true, message: `Vul ${label} in!` });
  }

  // Additional rule for positive numbers if type is "number"
  if (type === 'number') {
    rules.push({
      type: 'number',
      message: 'Please enter a valid positive number!',
      transform: (value: string) => (Number(value) <= 0 ? value : undefined),
    });
  }

  return (
    <div className="relative">
      <span className="text-sm block font-normal">{info}</span>

      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: darkMode ? '#cbd5e1' : '#000',
            },
          },
        }}
      >
        <Form.Item label={t(label)} name={name} rules={rules}>
          {type === 'textarea' ? (
            <textarea
              name={name}
              placeholder={t(placeholder)}
              className={`min-h-40 ${styles[style]} ${className}`}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                form.setFieldsValue({ [name]: e.target.value })
              }
            />
          ) : (
            <input
              type={type}
              name={name}
              placeholder={t(placeholder)}
              className={`${styles[style]} ${className}`}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                form.setFieldsValue({ [name]: e.target.value })
              }
            />
          )}
        </Form.Item>
      </ConfigProvider>
    </div>
  );
};

export default FormField;

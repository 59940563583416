import { Bulksim } from "../../containers/telecom/sim/ActivateSimBulk";
import { SimcardInterface } from "../../interfaces/Simcard";

export interface Option {
    label: string,
    value: string,
}

export class SimMapper { 
    public static mapSimToOptions(sim: SimcardInterface): Option {
        return {
            label: sim.sim_unique_nr,
            value: sim.sim_unique_nr,
        }
    }

    public static mapSimForBulk(sim: Bulksim) {
        return{
            simNr: sim.simNr,
            simTarifId: sim.simTarifId,
            simUserId: sim.simUserId
        }
    }
}